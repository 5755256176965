<template>
    <div>
        <b-row>
            <b-col sm="12">                
                <b-card>         
                    <b-row class="text-center mb-2">
                        <b-col sm="4" md="2" class="my-auto">                                    
                            <feather-icon icon="CalendarIcon" size="1.5x"  class="mr-1" style="color: rgba(216, 164, 0, 0.85);"  />       
                            De
                        </b-col>
                        <b-col sm="8" md="3" class="my-auto">                                    
                            <flat-pickr
                                v-model="starDateTwo"
                                class="form-control"
                                :config="configCalendar"
                            />        
                        </b-col>
                        <b-col sm="4" md="1" class="my-auto"> 
                            a
                        </b-col>
                        <b-col sm="8" md="3" class="my-auto">
                            <flat-pickr
                                v-model="endDateTwo"
                                class="form-control"
                                :config="configCalendar"
                            />        
                        </b-col>    
                        <b-col sm="12" md="3" class="my-auto"> 
                            <b-button
                                size="sm"
                                variant="primary"
                                @click="filterTwo"
                            >                                    
                                <feather-icon icon="FilterIcon" size="1x"/>   
                                Filtrar
                            </b-button>
                        </b-col>                            
                    </b-row> 

                    <!-- sorting  -->
                    <b-form-group
                        label="Ordenar"
                        label-size="sm"
                        label-align-sm="left"
                        label-cols-sm="2"
                        label-for="sortBySelect2"
                        class="mr-1 mb-md-0"
                    >
                        <b-input-group
                            size="sm"
                        >
                            <b-form-select
                                id="sortBySelect2"
                                v-model="sortBy2"
                                :options="sortOption2"
                            >
                                <template #first>
                                    <option value="">
                                        Ninguno
                                    </option>
                                </template>
                            </b-form-select>
                            <b-form-select
                                v-model="sortDesc2"
                                size="sm"
                                :disabled="!sortBy2"
                            >
                                <option :value="false">
                                    Asc
                                </option>
                                <option :value="true">
                                    Desc
                                </option>
                            </b-form-select>
                        </b-input-group>
                    </b-form-group>

                    <!-- filter -->
                    <b-form-group
                        label="Fitrar"
                        label-cols-sm="2"
                        label-align-sm="left"
                        label-size="sm"
                        label-for="filterInput2"
                        class="mt-1"
                    >
                        <b-input-group size="sm">
                            <b-form-input
                                id="filterInput2"
                                v-model="filter2"
                                type="search"
                                placeholder="Buscar ..."
                            />
                            <b-input-group-append>
                                <b-button
                                    :disabled="!filter2"
                                    @click="filter2 = ''"
                                >
                                    Limpiar
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                            
                    <json-excel :data="secondTableExport()" name="cantidad_de_informes_emitidos_por_rubros.xls"></json-excel>
                    <b-table
                        ref="table2Ref"
                        :fields="fields2"
                        :items="items2"
                        show-empty
                        empty-text="No se encontraron registros coincidentes"
                        striped
                        hover
                        responsive
                        class="position-relative text-center"
                        :per-page="perPage2"
                        :current-page="currentPage2"
                        :sort-by.sync="sortBy2"
                        :sort-desc.sync="sortDesc2"
                        :sort-direction="sortDirection2"
                        :filter="filter2"
                        :filter-included-fields="filterOn2"
                        @filtered="onFiltered2"
                    >               
                        <template #head()="data">
                            <div v-html="data.label"></div>
                        </template>

                        <template #cell(id)="data">
                            <span class="text-nowrap" v-if="data.item.business_area_name == 'TOTAL'"> </span>
                            <span class="text-nowrap" v-else>
                                {{ data.index+1 }}
                            </span>
                        </template>
                                                 
                        <template #cell(business_area_name)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.business_area_name }}</b>
                            <span v-else>{{ data.item.business_area_name }}</span>                       
                        </template>

                        <template #cell(total)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.total }}</b>
                            <span v-else>{{ data.item.total }}</span>                       
                        </template>
                        
                        <template #cell(percentage)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.percentage }}</b>
                            <span v-else>{{ data.item.percentage}}</span>                       
                        </template>
                        
                    </b-table>
                </b-card>                
            </b-col>
        </b-row>
    </div>  
</template>

<script>

    import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es.js';
    import flatPickr from 'vue-flatpickr-component';
    import VueApexCharts from 'vue-apexcharts';
    import JsonExcel from "./download-excel";
    import axios from 'axios';

    export default {
        components: {
            flatPickr,
            VueApexCharts,
            JsonExcel
        },
        data() {
            return {                
                perPage2: 50,
                pageOptions2: [10, 15, 20, 25, 30],
                totalRows2: 1,
                currentPage2: 1,
                sortBy2: '',
                sortDesc2: false,
                sortDirection2: 'asc',
                filter2: null,   
                filterOn2: [],     
                fields2: [],
                items2: [],
                
                starDateTwo: "",
                endDateTwo: "",
                
                configCalendar: {
                    locale:  SpanishLocale,
                    dateFormat: 'd-m-Y'
                }
            }
        },
        computed: {         
            sortOption2(){
                 return this.fields2
                    .filter(f => f.sortable)
                    .map(f => ({ text: f.label, value: f.key }));
            },  
        },
        beforeMount() {
            this.getDataTableTwo();
        },
        methods: {
            async getDataTableTwo(){
                await axios
                    .get(`${process.env.VUE_APP_HOST}/api/dashboard/reporteria/table-2?filterStartDate=${this.starDateTwo}&filterEndDate=${this.endDateTwo}`)
                    .then(response => {
                        if(response){
                                                                                   
                            this.fields2 = response.data.fields;
                            this.items2 = response.data.data; 
                            this.totalRows2 = response.data.data.length;
                            this.currentPage2 = 1;
                            this.sortBy2 = '';
                            this.sortDesc2 = false;
                            this.sortDirection2 = 'asc';
                            this.filter2 = null;
                        }
                    })
            },
            async filterTwo(){
               await this.getDataTableTwo();
                this.$refs['table2Ref'].refresh();
            },
            secondTableExport(){

                let array = [];

                for (let a=0; a<this.items2.length; a++)
                {
                    let objBody = {
                        'ITEM': (a+1 == this.items2.length ? '' : a+1),
                        'RUBRO': this.items2[a]["business_area_name"],
                        'CANTIDAD': this.items2[a]["total"],
                        'PORCENTAJE':  this.items2[a]["percentage"]
                    };

                    array.push(objBody);
                }
                
                return array;
            },            
            onFiltered2(filteredItems) {
                this.totalRows2 = filteredItems.length;
                this.currentPage2 = 1;
            }           
        }
    }

</script>

<style scoped>

    input[type=text] {
        border: none;
        border-radius: 0px;
        background: transparent;
        border-bottom: 1px solid #d8d6de;
    }

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    @import '@core/scss/vue/libs/chart-apex.scss';
</style>
